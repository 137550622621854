<template>
  <div>
    <NuxtLink
      :to="infoUrl"
      class="w-full flex items-center justify-center h-20 md:h-10 mx-auto"
      :style="`background-color: ${
        blok?.backgroundColor.color || '#CE5A67'
      }; color: ${blok?.textColor.color || '#ffffff'}`"
    >
      <div class="flex items-center overflow-hidden">
        <component :is="blok.item[0].component" :blok="blok.item[0]" />
      </div>
    </NuxtLink>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: Object,
})

const { locale } = useI18n()

const isLoggedIn = async () => {
  const { isLoggedIn } = useAuthUser()
  return import.meta.client ? await isLoggedIn() : false
}

const isLogged = await isLoggedIn()

const infoUrl = computed(() => {
  if (!props.blok) return
  if (!props.blok?.url?.url) return `/${locale.value}`

  const preferencesPath = useNuxtApp().$localePath({
    name: 'locale-account-preferences',
  })

  return props.blok?.url?.url &&
    props.blok?.url?.url.includes('newsletter') &&
    isLogged
    ? preferencesPath
    : props.blok?.url?.url
})
</script>
